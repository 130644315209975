import React from "react"
import { Link } from 'react-router-dom'
import { Box } from "../../components/Box"
import "./Notificacoes.css"

export const Notificacoes = props => {
    const DataNotificacoes = props.data?.notificacoes ?? [];

    return <div className="d-flex flex-column h-100">
        <Box>
            <div className="p-2 mx-2 h-100">
                <div className="d-flex flex-column h-100">
                    <Table title={"Notificações"} data={DataNotificacoes} />
                </div>
            </div>
        </Box>
    </div>
}

const Table = props => {
    const recordcount = props.data.length ?? 0
    return <div className="h-100 overflowy">
        <h5 className="text-center fw-bold text-decoration-underline">
            <Link className="text-decoration-none text-reset" to={"/notificacoes_admin"}>{`${props.title} (${recordcount})`}</Link>            
        </h5>
        {props.data.map((row, index) => (
            <div key={index} className="row text-nowrap">
                <div className="col-12 row-col">
                    <i className="fas fa-exclamation-triangle text-danger fa-lg pe-1"></i>{row.notificacao_mensagem}</div>
            </div>
        ))}
    </div>
}