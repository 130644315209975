import { showErrorMessage } from "../components/ErrorMessage"

const server = global.apiURL + '/rest'

export const api = {
    get: async (URL, authorizationValues = '') => {
        try {
            let headers = { 'Content-Type': 'application/json' }
            let authorization

            if (authorizationValues) {
                authorization = authorizationValues
            } else {
                authorization = JSON.parse(localStorage.getItem("user")).token ?? "undefined"
            }

            headers.filial = localStorage.getItem("branchCode") ?? "undefined"

            headers.Authorization = `basic ${authorization}`
            const response = await fetch(server + URL, { headers: headers })
            const data = await response.json()

            if (response.status != 200) throw new Error(data.error)
            return {
                isOk: true,
                data: data
            }
        } catch (e) {

            console.error(e.message)
            console.error(server + URL)
            showErrorMessage(`${e.message} - abra o console para mais detalhes.`)
            return {
                isOk: false,
                data: [],
                message: e.message
            }
        }
    }
}