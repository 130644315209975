import React, { useEffect } from "react"
import { Box } from "../../components/Box"
import "./OrcamentosTransferencias.css"
import { Table } from "./OrcamentosTransferencias";

export const AguardandoConferenciaEstoque = props => {
    const Data = props.data ?? [];

    useEffect(() => {
        const DivData = document.getElementById("orcamento_transferencia_aguardando_conferencia_estoque");
        if (DivData) {
            Data.length > 0
                ? DivData.classList.add("flash-red")
                : DivData.classList.remove("flash-red");
        }
    }, [Data])

    return <Table
        id={"orcamento_transferencia_aguardando_conferencia_estoque"}
        title={"Aguardando Conferência Estoque"}
        data={props.data}
        url={"/painel/orcamento_transferencia/aguardando_conferecia_estoque"}
        hideData={props.hideData || false}
        height={props.height}
    />
}