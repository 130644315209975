import React, { useEffect } from "react"
import { Box } from "../../components/Box"
// import data from '../../../data/ordens-servico.json'
import "./AguardandoAprovacaoDaConferencia.css"
import { Title } from "../../components/Title";

export const AguardandoAprovacaoDaConferencia = props => {
    const Data = props.data ?? [];

    useEffect(() => {
        const DivData = document.getElementById("aguardando_aprovacao_conferencia");
        if (DivData) {
            Data.length > 0
                ? DivData.classList.add("flash-red")
                : DivData.classList.remove("flash-red");
        }
    }, [Data])
    
    return <div className="d-flex flex-column h-100">
        <Box>
            <div className="p-2 mx-2 h-100">
                <div className="d-flex flex-column h-100">
                    <Table id={"aguardando_aprovacao_conferencia"} title={"Aguardando Aprovação da Conferência"} data={Data} />
                </div>
            </div>
        </Box>
    </div>
}

const Table = props => {
    const recordcount = props.data.length ?? 0
    return <div id={props.id} className={"h-100 " + props.className}>
        {/* <h5 className="text-center fw-bold text-decoration-underline">{props.title}</h5> */}
        <Title title={`${props.title} (${recordcount})`} url={"/painel/ordem_servico/aguardando_aprovacao_conferencia"} />
        <div className="row text-nowrap fw-bold">
            <div className="col-2">Número OS</div>
            <div className="col-4">Cliente</div>
            <div className="col-3">Técnico</div>
            <div className="col-3">Prioridade</div>
        </div>
        {props.data.map((row, index) => (
            <div key={index} className="row text-nowrap">
                <div className="col-2 row-col">{row.numero}</div>
                <div className="col-4 row-col">{row.cliente}</div>
                <div className="col-3 row-col">{row.consultor_tecnico}</div>
                <div className="col-3 row-col">{row.prioridade}</div>
            </div>
        ))}
    </div>
}