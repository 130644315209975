import React, { useState, useEffect, createContext, useContext, useCallback } from 'react'
import { api } from '../api/datasource'

function AuthProvider(props) {
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            const result = await getUser()
            if (result.isOk) {
                setUser(result.data)
            }
            setLoading(false)
        })()
    }, [])

    // getUser
    const getUser = async () => {
        try {
            let User
            let isOk = false
            const localUser = localStorage.getItem('user')

            // Fake User
            // isOk = true
            // User = {
            //     id: 1,
            //     usuario_login: 'pauloprecht01',
            //     usuario_senha: '1234',
            //     usuario_filial: '01'
            // }


            if (localUser) {
                isOk = true
                User = JSON.parse(localUser)
            }

            return {
                isOk: isOk,
                data: User
            };
        }
        catch (e) {
            console.error(e)
            return {
                isOk: false
            }
        }
    }

    // Login
    const signIn = useCallback(async (usuario, senha) => {
        try {

            var basicLogin = `${usuario}:${senha}`

            // Codificando a string para Base64
            var basicLoginBase64 = btoa(basicLogin);

            let _user = {}


            if (localStorage.getItem("TESTE")) {
                _user.token = 'basicLoginBase64'
                _user.login = 'usuario'
                _user.branches = [{ "Code": "01", "Description": "MATRIZ", "Title": "TESTE" }, { "Code": "02", "Description": "FILIAL", "Title": "TESTE 2" }]
                setUser(_user)
                localStorage.setItem("user", JSON.stringify(_user))
                return { auth: true }
            }


            const usuarios = await api.get('/check_security', basicLoginBase64)

            if (!usuarios.isOk) throw new Error('Falha na autenticação')
            if (usuarios.isOk) {
                // const filiais = await api.get('/api/framework/environment/v1/branches', basicLoginBase64)
                const filiais = await api.get('/api/framework/environment/v1/branches?order=Code&page=1&pageSize=20', basicLoginBase64)

                _user.token = basicLoginBase64
                _user.login = usuario
                _user.branches = filiais.data.items

                setUser(_user)
                localStorage.setItem("user", JSON.stringify(_user))
            }

            return {
                auth: true
            }
        } catch (error) {
            return {
                auth: false,
                message: error.message
            }
        }
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem('user')
        setUser()
    }, []);


    return (
        <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
    );
}



const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);


export { AuthProvider, useAuth }