import React from "react"
import { Box } from "../../components/Box"
import "./OrdensServico.css"
import { Title } from "../../components/Title"

export const OrdensServico = props => {
    return <div className="d-flex flex-column h-100">
        <h3 className="text-center fw-bold">ORDENS DE SERVIÇO</h3>
        <Box>
            <div className="p-2 mx-2 h-100">
                <div className="d-flex flex-column h-100">
                    {props.children}
                </div>
            </div>
        </Box>
    </div>
}

export const Table = props => {
    const containerClasses = props.hideData ? "" : "overflowy"
    const recordcount = props.data.length ?? 0

    return (
        <div id={props.id} className={containerClasses + " " + props.height}>
            {props.hideData ? (<Title title={`${props.title} (${recordcount})`} url={props.url} />) : (<>
                <Title title={`${props.title} (${recordcount})`} url={props.url} />
                <div className="row text-nowrap fw-bold">
                    <div className="col-2">Número OS</div>
                    <div className="col-4">Cliente</div>
                    <div className="col-3">Técnico</div>
                    <div className="col-3">Prioridade</div>
                </div>

                {props.data.map((row, index) => (
                    <div key={index} className="row text-nowrap">
                        <div className="col-2 row-col">{row.numero}</div>
                        <div className="col-4 row-col">{row.cliente}</div>
                        <div className="col-3 row-col">{row.consultor_tecnico}</div>
                        <div className="col-3 row-col">{row.prioridade}</div>
                    </div>
                ))}
            </>
            )}
        </div>
    )
}