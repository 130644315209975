import { api } from '../api/datasource'
import { localData } from '../data_painel_cocamar'

const GetMaxNumero = (objetos) => {
    let maxNumero = -Infinity;

    for (const objeto of objetos) {
        // Extrair o valor da chave "numero" do objeto
        const numeroAtual = objeto.numero;

        // Converter o valor para um número inteiro removendo os zeros à esquerda
        const numeroInteiro = parseInt(numeroAtual, 10);

        // Verificar e atualizar o máximo, se necessário
        if (numeroInteiro > maxNumero) {
            maxNumero = numeroInteiro;
        }
    }
    return maxNumero
}

const OrdenaJSON = (dataJSON) => {

    const data = JSON.parse(JSON.stringify(dataJSON))

    const JSONOrdenado = data.sort((a, b) => {

        const prioridadeOrder = {
            'URGENTE': 9,
            'NO BALCAO': 4,
            'VINDO RETIRAR': 3,
            'TRANSPORTADORA': 2,
            'USINA': 1,
            'BAIXA': 0
        }

        // Compara as prioridades diretamente
        const prioridadeComparison = prioridadeOrder[b.prioridade] - prioridadeOrder[a.prioridade]

        // Se as prioridades são diferentes, retorna a comparação das prioridades
        if (prioridadeComparison !== 0) {
            return prioridadeComparison
        }

        return parseInt(a.numero, 10) - parseInt(b.numero, 10)
    })

    return JSONOrdenado
}


const playAudio = (data, quadro) => {
    try {
        const audioEnable = true
        const lastNumero = GetMaxNumero(data)

        if (
            data.length > 0 &&
            lastNumero > localStorage.getItem(`lastNumeroQuadro${quadro}`) &&
            audioEnable
        ) {

            const audioElement = document.getElementById('audio' + quadro)
            audioElement.play()
                .then(() => {
                    localStorage.setItem(`lastNumeroQuadro${quadro}`, lastNumero)
                    console.log('Áudio reproduzido com sucesso. Quadro ' + quadro)
                })
                .catch(error => console.error('Erro ao reproduzir áudio:', error))
        } else {
            console.log(quadro + ' audio ja reproduzido')
        }
    } catch (error) {
        console.error(error)
    }
}

const esperar = (segundos) => {
    return new Promise(resolve => setTimeout(resolve, segundos * 1000));
}

export const GetData = async () => {
    let res = []

    if (localStorage.getItem("TESTE") === "logged") {
        res = localData
    } else {
        res = await api.get('/sibe/gestaodeatendimentos/painel')
    }

    const data = {
        orcamento_transferencia: {
            aguardando_conferencia_estoque: OrdenaJSON(res?.data?.filter(item => item.quadro == "1")) || [],
            aguardando_faturamento_classificacao: OrdenaJSON(res?.data?.filter(item => item.quadro == "2")) || []
        },
        notificacoes: (res && res.data) ? OrdenaJSON(res.data.filter(item => item.quadro == "3")) : [],
        ordens_de_servico: {
            aguardando_liberacao_oficina: OrdenaJSON(res?.data?.filter(item => item.quadro == "4")) || [],
            aguardando_conferencia_estoque: OrdenaJSON(res?.data?.filter(item => item.quadro == "5")) || []
        },
        aguardando_aprovacao_da_conferencia: OrdenaJSON(res?.data?.filter(item => item.quadro == "6")) || []
    }

    const data1 = data.orcamento_transferencia.aguardando_conferencia_estoque
    // const data2 = data.orcamento_transferencia.aguardando_faturamento_classificacao
    const data3 = data.notificacoes
    const data4 = data.ordens_de_servico.aguardando_liberacao_oficina
    const data5 = data.ordens_de_servico.aguardando_conferencia_estoque
    const data6 = data.aguardando_aprovacao_da_conferencia


    if (localStorage.getItem("TESTE") !== "logged") {
        playAudio(data1, 1)
        playAudio(data3, 3)
        playAudio(data4, 4)
        playAudio(data5, 5)
        playAudio(data6, 6)
    }

    await esperar(2)
    return data
}

export const GetDataNotificacao = async () => {
    const res = await api.get('/sibe/gestaodeatendimentos/notificacoes')
    return res.data
}