import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { useNavigate } from "react-router-dom";

import './login-form.css';

export const LoginForm = () => {

  const navigate = useNavigate()
  const { signIn } = useAuth();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    const usuario = document.getElementById('usuario').value
    const senha = document.getElementById('senha').value
    let login

    if (usuario == "teste" && senha == "teste") {    
      console.log('1')
      localStorage.setItem("TESTE", "logged")
      await signIn(usuario, senha)

      login = {
        auth : true
      }

      navigate('/painel')
      console.log('2')
      return
    }

    login = await signIn(usuario, senha)

    if (!login.auth) {
      const messages = document.getElementById("messages");
      messages.innerText = login.message
      messages.classList.remove("invisible")
      console.error('Error: ' + login.message)
    }

    if (login.auth) {
      navigate('/painel')
    }

  }, [signIn])

  return <div className="d-flex flex-coumn min-vh-100 min-vw-100">
    <div className="d-flex flex-grow-1 justify-content-center align-items-center">

      <form id="login_content" className={'login-form p-4 rounded-2'} onSubmit={onSubmit}>
        <div className="login-container">
          <h2 className="theme-primary-font-color">Área Restrita</h2>
          <input type="text" className="form-control" id="usuario" placeholder="Login" name="usuario" required />
          <input type="password" className="form-control mt-2" id="senha" placeholder="Senha" name="senha" required />
          <button type="submit" className="btn theme-colors-items mt-2 w-100">Entrar</button>
        </div>
        <p id='messages' className="text-danger invisible">This text represents danger.</p>
      </form>
    </div>
  </div>
}