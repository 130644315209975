import React, { useEffect, useState } from 'react'
import { Header } from "../components/Header"
import { GetData } from '../utils/GetData'
import { OrcamentosTransferencias } from "./OrcamentosTransferencias/OrcamentosTransferencias"
import { OrdensServico } from "./OrdensServico/OrdensServico"

import { Main } from '../components/Main'

import { AguardandoConferenciaEstoque as OrcamentosTransferenciasAguardandoConferenciaEstoque } from './OrcamentosTransferencias/AguardandoConferenciaEstoque'
import { AguardandoFaturamentoClassificacao as OrcamentosTransferenciasAguardandoFaturamentoClassificacao } from './OrcamentosTransferencias/AguardandoFaturamentoClassificacao'

import { AguardandoLiberacaoOficina as OrdensServicoAguardandoLiberacaoOficina } from './OrdensServico/AguardandoLiberacaoOficina'
import { AguardandoConferenciaEstoque as OrdensServicoAguardandoConferenciaEstoque } from './OrdensServico/AguardandoConferenciaEstoque'

import './Painel.css'
import { AguardandoAprovacaoDaConferencia } from './AguardandoAprovacaoDaConferencia/AguardandoAprovacaoDaConferencia'

export const PainelMax = props => {
    const [data, setData] = useState([])
    const [initLoaded, setInitLoaded] = useState(false)

    const fetchData = async () => {
        const getData = await GetData()
        setData(getData)
        setInitLoaded(true)
    }

    useEffect(() => {
        fetchData()
        const interval = setInterval(fetchData, 60000)
        return () => clearInterval(interval)
    }, [])


    return <Main>
        <div>
            {!initLoaded ? (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white opacity-75">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </div>
                </div>
            ) : (<></>)}
        </div>

        <div className="row m-0 h-100">
            <div className="col-sm-12 p-2 h-100">

                {/* OrcamentosTransferenciasAguardandoConferenciaEstoque */}
                {props.quadro === 1 && (
                    <OrcamentosTransferencias>
                        <OrcamentosTransferenciasAguardandoConferenciaEstoque data={data?.orcamento_transferencia?.aguardando_conferencia_estoque ?? []} height={"h-100"} />
                    </OrcamentosTransferencias>
                )}

                {/* AguardandoFaturamentoClassificacao */}
                {props.quadro === 2 && (
                    <OrcamentosTransferencias>
                        <OrcamentosTransferenciasAguardandoFaturamentoClassificacao data={data?.orcamento_transferencia?.aguardando_faturamento_classificacao ?? []} height={"h-100"} />
                    </OrcamentosTransferencias>
                )}

                {/* OrdensServicoAguardandoLiberacaoOficina */}
                {props.quadro === 4 && (
                    <OrdensServico>
                        <OrdensServicoAguardandoLiberacaoOficina data={data?.ordens_de_servico?.aguardando_liberacao_oficina ?? []} height={"h-100"} />
                    </OrdensServico>
                )}

                {/* OrdensServicoAguardandoConferenciaEstoque */}
                {props.quadro === 5 && (
                    <OrdensServico>
                        <OrdensServicoAguardandoConferenciaEstoque data={data?.ordens_de_servico?.aguardando_conferencia_estoque ?? []} height={"h-100"} />
                    </OrdensServico>
                )}

                {/* OrdensServicoAguardandoConferenciaEstoque */}
                {props.quadro === 6 && (
                    <AguardandoAprovacaoDaConferencia data={data?.aguardando_aprovacao_da_conferencia ?? []} height={"h-100"} />
                )}



            </div>

        </div>



    </Main>
}