import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Route, Routes, useNavigate, BrowserRouter } from 'react-router-dom'
import { LoginForm } from './usuario/login/login-form'
import { AuthProvider, useAuth } from './contexts/auth'
import RoutesAuth from './RoutesAuth'
import { ErrorMessage } from './components/ErrorMessage'
import './index.css'
import { Teste } from './components/Teste'


console.log('app start')
localStorage.setItem("audio_enable", true)

localStorage.setItem(`lastNumeroQuadro1`, -1)
localStorage.setItem(`lastNumeroQuadro2`, -1)
localStorage.setItem(`lastNumeroQuadro3`, -1)
localStorage.setItem(`lastNumeroQuadro4`, -1)
localStorage.setItem(`lastNumeroQuadro5`, -1)
localStorage.setItem(`lastNumeroQuadro6`, -1)

const LoadPanel = () => {
  return <div className="container-fluid vh-100 d-flex align-items-center justify-content-center">
    <div className="text-center">
      <h1>Carregando</h1>
      <h1 className="spinner-border"></h1>
    </div>
  </div>
}

const Redirect = ({ to }) => {
  let navigate = useNavigate()
  useEffect(() => navigate(to))
  return null
}

const App = () => {
  let { user, loading } = useAuth()

  if (loading) {
    return <LoadPanel />
  }

  if (user) return <RoutesAuth />

  return (
    <Routes>
      <Route path='/' element={<LoginForm />} />
      <Route path='/teste' element={<Teste />} />
      <Route path="*" element={<Redirect to="/" />} />
    </Routes>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ErrorMessage />
    <AuthProvider>
      <App />
    </AuthProvider>
  </BrowserRouter>
)