import React from "react"
import { Link } from 'react-router-dom'
import { SetFilial } from "../pages/SetFilial/SetFilial"
import './template.css'

export const Header = props => {

    return <div className={"row align-content-center justify-content-between align-items-center mx-2 line-header-height"}>
        <div className="col-2 ps-2 text-break">
            <SetFilial />
        </div>

        <div className="d-flex col-8 justify-content-center">
            <Link className="theme-colors-items rounded-5 h1 py-2 px-4 my-0 text-decoration-none text-reset" to={"/painel"}>GESTÃO DE ATENDIMENTOS</Link>            
        </div>

        <div className="d-flex col-2 justify-content-end">
            <img className="p-2" id="logo" src={"/logo.png"} alt="Logo" />
        </div>
    </div>
}