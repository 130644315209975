import React from "react"
import { useAuth } from "../contexts/auth"
import { useNavigate } from "react-router-dom"

export const Teste = props => {
    const navigate = useNavigate()
    const { signIn } = useAuth()


    const isLoggedIn = localStorage.getItem("TESTE")
    console.log("Valor de isLoggedIn:", isLoggedIn);

    return (
        <div className="container-fluid">
            <h1>TESTE</h1>
            {isLoggedIn === "logged" ? (
                <button type="button" className="btn btn-danger"
                    onClick={() => {
                        localStorage.removeItem("branchCode")
                        localStorage.removeItem("branchMenuText")
                        localStorage.removeItem("TESTE")
                    }}>
                    Logoff
                </button>
            ) : (
                <button type="button" className="btn btn-primary"
                    onClick={async () => {
                        console.log("TESTE")
                        localStorage.setItem("TESTE", "logged")

                        const login = await signIn("usuario", "senha")
                    }}>
                    Login
                </button>
            )}
        </div>
    );
}