import React, { useEffect, useState } from 'react'
import { Main } from '../components/Main'
import { GetData } from '../utils/GetData'
import { OrcamentosTransferencias } from "./OrcamentosTransferencias/OrcamentosTransferencias"
import { OrdensServico } from "./OrdensServico/OrdensServico"
import { Notificacoes } from "./Notificacoes/Notificacoes"
import { AguardandoAprovacaoDaConferencia } from "./AguardandoAprovacaoDaConferencia/AguardandoAprovacaoDaConferencia"
import { AguardandoConferenciaEstoque as OrcamentosTransferenciasAguardandoConferenciaEstoque } from './OrcamentosTransferencias/AguardandoConferenciaEstoque'
import { AguardandoFaturamentoClassificacao as OrcamentosTransferenciasAguardandoFaturamentoClassificacao } from './OrcamentosTransferencias/AguardandoFaturamentoClassificacao'
import { AguardandoConferenciaEstoque as OrdensServicoAguardandoConferenciaEstoque } from './OrdensServico/AguardandoConferenciaEstoque'
import { AguardandoLiberacaoOficina as OrdensServicoAguardandoLiberacaoOficina } from './OrdensServico/AguardandoLiberacaoOficina'
import './Painel.css'

export const Painel = () => {
    const [data, setData] = useState([])
    const [initLoaded, setInitLoaded] = useState(false)

    const fetchData = async () => {
        const getData = await GetData()        
        setData(getData)
        setInitLoaded(true)
    }

    useEffect(() => {

        localStorage.removeItem("audioplaying")
        // localStorage.removeItem("audio_enable")

        localStorage.removeItem("audio1")
        localStorage.removeItem("audio2")
        localStorage.removeItem("audio3")
        localStorage.removeItem("audio4")
        localStorage.removeItem("audio5")
        localStorage.removeItem("audio6")



        fetchData()
        const interval = setInterval(fetchData, 3000)
        // const interval = setInterval(fetchData, 60000)
        return () => clearInterval(interval)
    }, [])


    const handleAudioPlay = (event) => {
        localStorage.setItem('audioplaying', true)        
    }

    const handleAudioEnd = () => {
        localStorage.removeItem('audioplaying')        
    }

    return <Main>
        <audio id={'audio1'}><source src={'/audio/1.mp3'} type="audio/mpeg" onPlay={handleAudioPlay} onEnded={handleAudioEnd} onPause={handleAudioEnd} /></audio>
        <audio id={'audio3'}><source src={'/audio/3.mp3'} type="audio/mpeg" onPlay={handleAudioPlay} onEnded={handleAudioEnd} onPause={handleAudioEnd} /></audio>
        <audio id={'audio4'}><source src={'/audio/4.mp3'} type="audio/mpeg" onPlay={handleAudioPlay} onEnded={handleAudioEnd} onPause={handleAudioEnd} /></audio>
        <audio id={'audio5'}><source src={'/audio/5.mp3'} type="audio/mpeg" onPlay={handleAudioPlay} onEnded={handleAudioEnd} onPause={handleAudioEnd} /></audio>
        <audio id={'audio6'}><source src={'/audio/6.mp3'} type="audio/mpeg" onPlay={handleAudioPlay} onEnded={handleAudioEnd} onPause={handleAudioEnd} /></audio>

        <div>
            {!initLoaded ? (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white opacity-75">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </div>
                </div>
            ) : (<></>)}
        </div>

        {/* Linha 1 */}
        <div className="row m-0 line-1">
            <div className="col-sm-6 p-2 h-100">
                <OrcamentosTransferencias>
                    <OrcamentosTransferenciasAguardandoConferenciaEstoque
                        data={data?.orcamento_transferencia?.aguardando_conferencia_estoque ?? []}
                        height={"h-100"}
                    />
                    <hr />
                    <OrcamentosTransferenciasAguardandoFaturamentoClassificacao
                        data={data?.orcamento_transferencia?.aguardando_faturamento_classificacao ?? []}
                        hideData={true}
                    />
                </OrcamentosTransferencias>
            </div>

            <div className="col-sm-6 p-2 h-100">
                <OrdensServico>
                    <OrdensServicoAguardandoLiberacaoOficina
                        data={data?.ordens_de_servico?.aguardando_liberacao_oficina ?? []}
                        // height={"h-50"}
                        hideData={true}
                    />
                    <hr />
                    <OrdensServicoAguardandoConferenciaEstoque
                        data={data?.ordens_de_servico?.aguardando_conferencia_estoque ?? []}
                        height={"h-100"}
                    // hideData={true}
                    />
                </OrdensServico>

            </div>
        </div>

        {/* Linha 2 */}
        <div className="row m-0 line-2">
            <div className="col-sm-6 p-2 h-100"><Notificacoes data={data} /></div>
            <div className="col-sm-6 p-2 h-100"><AguardandoAprovacaoDaConferencia data={data?.aguardando_aprovacao_da_conferencia ?? []} /></div>
        </div>

    </Main>
}