import React, { useEffect } from "react"
import { Box } from "../../components/Box"
import "./OrcamentosTransferencias.css"
import { Table } from "./OrcamentosTransferencias";

export const AguardandoFaturamentoClassificacao = props => {

    return <Table
        id={"orcamento_transferencia_aguardando_faturamento_classificacao"}
        title={"Aguardando Faturamento/Classificação"}
        data={props.data}
        height={props.height}
        url={"/painel/orcamento_transferencia/aguardando_faturamento_classificacao"}
        hideData={props.hideData || false}
    />
}

