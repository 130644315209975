import React, { useEffect, useState } from "react"
import { Box } from "../../components/Box"
import "./Notificacoes.css"
import { Header } from "../../components/Header"
import { useAuth } from "../../contexts/auth"
import { GetDataNotificacao } from "../../utils/GetData"

import { ConfirmarCiencia } from "./ConfirmarCiencia"

export const NotificacoesAdmin = (props) => {
  const [data, setData] = useState([])
  const [forceRefresh, setForceRefresh] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const { user } = useAuth()

  useEffect(() => {
    const fetchData = async () => {
      // console.log('fetchData')
      try {
        const res = await GetDataNotificacao()
        setData(res)
      } catch (error) {
        console.error('Erro ao carregar dados:', error)
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [user.usuario_filial, forceRefresh])

  if (loading) return <div className="text-center">Carregando...</div>
  if (error) return <div className="text-center text-danger">Erro ao carregar dados: {error.message}</div>


  return (
    <div className="d-flex flex-column h-100">
      <Header className={"line-header"} />
      <Box className="mb-2">
        <div className="p-2 mx-2 h-100">
          <div className="d-flex flex-column h-100">
            <Table title={"Notificações"} data={data} user={user} forceRefresh={forceRefresh} setForceRefresh={setForceRefresh} />
          </div>
        </div>
      </Box>
    </div>
  )
}

const Table = (props) => {
  const recordcount = props.data.length ?? 0
  return (
    <div className="h-100 overflowy">

      <div className="d-flex align-items-center align-content-center border-secondary border-bottom mb-2 me-2 py-1">
        <input id="checkAll" name="checkAll" type="checkbox" className="form-check-input me-2" value="-1"
          onClick={() => {
            const checkboxes = document.querySelectorAll('form input[type="checkbox"]')
            const checkAllCheckbox = document.getElementById('checkAll')
            const isChecked = checkAllCheckbox.checked
            checkboxes.forEach(checkbox => checkbox.checked = isChecked)
          }}
        />
        <span className="fw-bold me-2">{props.title}</span>

        <button className="btn btn-link btn-sm p-0 " type="button"
          onClick={async () => {
            await ConfirmarCiencia(props.user)
            props.setForceRefresh(props.forceRefresh + 1)
          }}
        >Confirmar Ciência</button>
      </div>

      <form>

        {props.data.map((row, index) => {
          return <div key={index} className="form-check text-nowrap">

            <input id={"notificacao" + index} name={"notificacao" + index} type="checkbox" className="form-check-input" value={row.gestaoatendimento_id} />
            <label htmlFor={"notificacao" + index} className="form-check-label">{row.gestaoatendimento_mensagem}</label>

          </div>
        })}
      </form>
    </div>
  )
}