import React from 'react'
import { useAuth } from '../../contexts/auth'

export const SetFilial = () => {
  const { signOut } = useAuth()

  const branches = JSON.parse(localStorage.getItem("user")).branches

  return <div className="d-flex justify-content-center align-items-center mx-auto theme-colors-items rounded-5">
    <div className="dropdown theme-colors-items">
      <button type="button" className="btn  dropdown-toggle" data-bs-toggle="dropdown">
        {`${localStorage.getItem("branchMenuText") ?? "EMPRESA"}`}
      </button>
      <ul className="dropdown-menu">

        {branches.map((branch) => (
          <li key={branch.Code}>
            <button
              key={branch.Code}
              type="button"
              className="dropdown-item link-styled"
              onClick={() => {                
                localStorage.setItem("branchCode", branch.Code)
                localStorage.setItem("branchMenuText", `${branch.Code}`)
                window.location.href = '/'
                setTimeout(function () { window.location.reload() }, 1000)
              }}
            >{`${branch.Code}`}</button>
          </li>
        ))}
        <li><hr className="dropdown-divider" /></li>
        <li><button className="dropdown-item link-styled" onClick={() => { signOut() }}>Sair</button></li>
      </ul>
    </div>
  </div>
}


