import { api } from "../../api/datasource"

export const ConfirmarCiencia = async (user) => {
  const filial = localStorage.getItem("branchCode") ?? "undefined"

  const notificacoes = document.querySelectorAll('form input[type="checkbox"]')
  const notificacoesMarcadas = []
 
  notificacoes.forEach(checkbox => { if (checkbox.checked) notificacoesMarcadas.push(checkbox.value) })

  for (const notificacaoId of notificacoesMarcadas) {
    let routePath = `/sibe/gestaodeatendimentos/ciencia?filial=${filial}&notificacao=${notificacaoId}&usuario=${user.login}`
    const res = await api.get(routePath)
  }

  // const checkboxes = document.querySelectorAll('form input[type="checkbox"]');

  notificacoes.forEach(checkbox => checkbox.checked = false)

  return true

}