import React, { useEffect } from "react"
import { Box } from "../../components/Box"
import "./OrdensServico.css"
import { Table } from "./OrdensServico";

export const AguardandoConferenciaEstoque = props => {
    const Data = props.data ?? [];
    useEffect(() => {
        const DivData = document.getElementById("ordens_servico_aguardando_conferencia_estoque")
        DivData.classList.add("flash-red")
        if (DivData) {
            Data.length > 0 ? DivData.classList.add("flash-red") : DivData.classList.remove("flash-red");
        }
    }, [Data])


    return <Table
        id={"ordens_servico_aguardando_conferencia_estoque"}
        title={"Aguardando Conferência Estoque"}
        data={props.data}
        height={props.height}
        url={"/painel/ordem_servico/aguardando_conferencia_estoque"}
        hideData={props.hideData || false}
    />

}