import React from 'react'
import { Header } from './Header'
// import './Main.css'

export const Main = props => {


    return <React.Fragment>
        <div className="container-fluid vh-100">
            <div className="d-flex flex-column vh-100">
                <Header />
                {props.children}

                {/* <div className="row m-0 line-1">
                    <div className="col-sm-6 p-2 h-100"><OrcamentosTransferencias data={data} /></div>
                    <div className="col-sm-6 p-2 h-100"><OrdensServico data={data} /></div>
                </div>

                <div className="row m-0 line-2">
                    <div className="col-sm-6 p-2 h-100"><Notificacoes data={data} /></div>
                    <div className="col-sm-6 p-2 h-100"><AguardandoAprovacaoDaConferencia data={data} /></div>
                </div> */}
            </div>
        </div>
    </React.Fragment >


    return <React.Fragment>
        {/* <Header /> */}


        {/* <TabPanel animationEnabled={false} swipeEnabled={true}>

            <Item title="Data Grid 1" icon="rowfield">
                <CentroCusto />
            </Item>

            <Item title="Data Grid 1" icon="rowfield">
                <DocumentoList hidePageContainer={true} />
            </Item>


        </TabPanel> */}



        <div id="main-container" className="container-fluid py-4 px-4">
            {props.children}
        </div>
    </React.Fragment>
}