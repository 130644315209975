import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

import { Painel } from './pages/Painel'
import { PainelMax } from './pages/PainelMax'
import { NotificacoesAdmin } from './pages/Notificacoes/NotificacoesAdmin'
import { Teste } from './components/Teste'

const Redirect = ({ to }) => {
    let navigate = useNavigate()
    useEffect(() => navigate(to))
    return null
}

export default () => {
    return (<Routes>
        <Route path='/teste' element={<Teste />} />
        <Route path='/painel' element={<Painel />} />
        <Route path='/' element={<Painel />} />
        <Route path='/notificacoes_admin' element={<NotificacoesAdmin />} />
        <Route path='/painel/orcamento_transferencia/aguardando_conferecia_estoque' element={<PainelMax quadro={1} />} />
        <Route path='/painel/orcamento_transferencia/aguardando_faturamento_classificacao' element={<PainelMax quadro={2} />} />
        <Route path='/painel/ordem_servico/aguardando_liberacao_oficina' element={<PainelMax quadro={4} />} />
        <Route path='/painel/ordem_servico/aguardando_conferencia_estoque' element={<PainelMax quadro={5} />} />
        <Route path='/painel/ordem_servico/aguardando_aprovacao_conferencia' element={<PainelMax quadro={6} />} />        
        <Route path="*" element={<Redirect to="/" />} />
    </Routes>)
}