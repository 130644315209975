import React, { useEffect } from "react"
import { Box } from "../../components/Box"
import "./OrdensServico.css"
import { Table } from "./OrdensServico"

export const AguardandoLiberacaoOficina = props => {


    const Data = props.data ?? [];

    useEffect(() => {
        const DivData = document.getElementById("ordens_servico_aguardando_liberacao_oficina");
        if (DivData) {
            Data.length > 0 ? DivData.classList.add("flash-yellow") : DivData.classList.remove("flash-yellow");
        }
    }, [Data])

    return <Table
        id={"ordens_servico_aguardando_liberacao_oficina"}
        title={"Aguardando Liberação Oficina"}
        data={props.data}
        url={"/painel/ordem_servico/aguardando_liberacao_oficina"}
        height={props.height}
        hideData={props.hideData || false}
    />
}